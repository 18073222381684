import React, { useState }from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'

const movAppear = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: 'easeOut',
    },
  },
}

interface Props {
  children?: React.ReactNode
  promptIcon: string
  promptText: string
  id: string
}

const Expandable = ({ children, promptIcon, promptText, id }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <AnimatePresence exitBeforeEnter>
      {!open && (
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            variants={movAppear}
            exit={'hidden'}
            key={id + '-button'}
            className="pb-2"
          >
            <Button
              className="btn-cover"
              variant="outline-light" 
              onClick={() => setOpen(!open)}
            >
              {promptText}
              <span className='ps-2'>
                <FontAwesomeIcon icon={promptIcon} />
              </span>
            </Button>
          </motion.div>
        )}
        {open && (
          <motion.div
            initial={'hidden'}
            animate={'visible'}
            variants={movAppear}
            key={id + '-text'}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

Expandable.defaultProps = {
  promptIcon: 'chevron-down',
  promptText: 'More',
  id: 'expandable',
}

export default Expandable
