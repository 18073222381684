import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import './image.scss'

interface Props {
  children: React.ReactNode
  filename: string
  fluid: string
  alt: string
  outline: boolean
  texture: boolean
  container: string
}

const Image = ({ filename, fluid, alt, outline, texture, container, ...otherProps }: Props) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          full: fluid(maxWidth: 2560){
            originalName
            ...GatsbyImageSharpFluid
          }
          thumb: fluid(
            maxWidth: 512,
            maxHeight: 512
          ){
            ...GatsbyImageSharpFluid
          }
          duotone: fluid(
            maxWidth: 2560,
            duotone: {
              highlight: "#7386E6",
              shadow: "#242633",
            }
          ){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const node = allImageSharp.nodes.find(n => n.full.originalName === filename)
  const img = <Img
      fluid={node && node[fluid]}
      alt={alt}
      className={`${outline && 'img-outline'} ${texture && 'img-texture'}`}
      {...otherProps}
    />
  if (container) {
    return(
      <div className={container}>
        <div>
          {img}
        </div>
      </div>        
    )
  }
  return img
}

Image.defaultProps = {
  fluid: 'full',
}

export default Image
