import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
export const movAppear = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 1.5,
      ease: 'easeOut'
    }
  }
};
export const movRevealLeft = {
  hidden: {
    opacity: 0,
    x: '10vw'
  },
  visible: {
    opacity: 1,
    x: '0',
    transition: {
      duration: 1,
      ease: 'easeOut'
    }
  }
};
export const movRevealRight = {
  hidden: {
    opacity: 0,
    x: '-10vw'
  },
  visible: {
    opacity: 1,
    x: '0',
    transition: {
      duration: 1,
      ease: 'easeOut'
    }
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const BackgroundImage = makeShortcode("BackgroundImage");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const AnimateInView = makeShortcode("AnimateInView");
const Button = makeShortcode("Button");
const FontAwesomeIcon = makeShortcode("FontAwesomeIcon");
const BackgroundGradient = makeShortcode("BackgroundGradient");
const Image = makeShortcode("Image");
const Expandable = makeShortcode("Expandable");
const Obfuscate = makeShortcode("Obfuscate");
const layoutProps = {
  movAppear,
  movRevealLeft,
  movRevealRight,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <a id="portfolio" />
    <Section variant="accent" mdxType="Section">
  <BackgroundImage filename="site-bg_pixabay-2603990.jpg" height="30rem" mdxType="BackgroundImage">
    <Container className="h-100" fluid mdxType="Container">
      <Row className="h-100 justify-content-center align-items-center" mdxType="Row">
        <Col md={5} lg={4} className="d-none d-md-block" mdxType="Col"></Col>
        <Col xs={12} sm={10} md={5} lg={4} mdxType="Col">
          <AnimateInView variant={movRevealLeft} mdxType="AnimateInView">
            <h2 className="cover">Portfolio</h2>
            <p>
              Selected intermedia art projects from John Crawford and partners, connecting diverse communities through
              embodied interaction in dance, theatre and music.
            </p>
            <Button className="btn-cover" variant="outline-light" href="https://embodied.net/" mdxType="Button">
              More
              <span className="ps-2">
                <FontAwesomeIcon icon="external-link-alt" mdxType="FontAwesomeIcon" />
              </span>
            </Button>
          </AnimateInView>
        </Col>
      </Row>
    </Container>
  </BackgroundImage>
    </Section>
    <a id="research" />
    <Section variant="accent-1" mdxType="Section">
  <BackgroundImage filename="site-bg_pixabay-3501134.jpg" height="30rem" mdxType="BackgroundImage">
    <Container className="h-100" fluid mdxType="Container">
      <Row className="h-100 justify-content-center align-items-center" mdxType="Row">
        <Col xs={12} sm={10} md={5} lg={4} mdxType="Col">
          <AnimateInView variant={movRevealRight} mdxType="AnimateInView">
            <h2 className="cover">Research</h2>
            <p>
              Applied research in socially engaged artmaking, emergent media and connected design, creating interactive
              experiences to engage people in tangible, sensorial and experiential ways.
            </p>
            <Button className="btn-cover" variant="outline-light" href="https://emrg.embodied.net/" mdxType="Button">
              More
              <span className="ps-2">
                <FontAwesomeIcon icon="external-link-alt" mdxType="FontAwesomeIcon" />
              </span>
            </Button>
          </AnimateInView>
        </Col>
        <Col md={5} lg={4} className="d-none d-md-block" mdxType="Col"></Col>
      </Row>
    </Container>
  </BackgroundImage>
    </Section>
    <a id="eco-art" />
    <Section variant="accent-2" mdxType="Section">
  <BackgroundImage filename="site-bg_pixabay-4538080.jpg" height="30rem" mdxType="BackgroundImage">
    <Container className="h-100" fluid mdxType="Container">
      <Row className="h-100 justify-content-center align-items-center" mdxType="Row">
        <Col md={5} lg={4} className="d-none d-md-block" mdxType="Col"></Col>
        <Col xs={12} sm={10} md={5} lg={4} mdxType="Col">
          <AnimateInView variant={movRevealLeft} mdxType="AnimateInView">
            <h2 className="cover">Eco ArtLab</h2>
            <p>
              Artistic performances and exhibitions that enhance ecological awareness, engaging with new forms of
              expression enabled by the emerging media technologies of our time.
            </p>
            <Button className="btn-cover" variant="outline-light" href="https://artlab.eco" mdxType="Button">
              More
              <span className="ps-2">
                <FontAwesomeIcon icon="external-link-alt" mdxType="FontAwesomeIcon" />
              </span>
            </Button>
          </AnimateInView>
        </Col>
      </Row>
    </Container>
  </BackgroundImage>
    </Section>
    <a id="courses" />
    <Section variant="accent-3" mdxType="Section">
  <BackgroundImage filename="site-bg_pixabay-4538085.jpg" height="30rem" mdxType="BackgroundImage">
    <Container className="h-100" fluid mdxType="Container">
      <Row className="h-100 justify-content-center align-items-center" mdxType="Row">
        <Col xs={12} sm={10} md={5} lg={4} mdxType="Col">
          <AnimateInView variant={movRevealRight} mdxType="AnimateInView">
            <h2 className="cover">Courses</h2>
            <p>
              Courses and workshops to engage and challenge technology-savvy art makers, storytellers and designers,
              providing opportunities to enhance creativity and develop new skills.
            </p>
            <Button className="btn-cover" variant="outline-light" href="https://emrg.embodied.net/course" mdxType="Button">
              More
              <span className="ps-2">
                <FontAwesomeIcon icon="external-link-alt" mdxType="FontAwesomeIcon" />
              </span>
            </Button>
          </AnimateInView>
        </Col>
        <Col md={5} lg={4} className="d-none d-md-block" mdxType="Col"></Col>
      </Row>
    </Container>
  </BackgroundImage>
    </Section>
    <a id="collabs" />
    <Section variant="accent-4" mdxType="Section">
  <BackgroundImage filename="site-bg_pixabay-3632496.jpg" height="30rem" mdxType="BackgroundImage">
    <Container className="h-100" fluid mdxType="Container">
      <Row className="h-100 justify-content-center align-items-center" mdxType="Row">
        <Col md={5} lg={4} className="d-none d-md-block" mdxType="Col"></Col>
        <Col xs={12} sm={10} md={5} lg={4} mdxType="Col">
          <AnimateInView variant={movRevealLeft} mdxType="AnimateInView">
            <h2 className="cover">Collaboratories</h2>
            <p>
              Informal educational exchange programs, supported by UC Irvine's Embodied Media Research Group, that
              connect students and faculty with peers at other leading institutions.
            </p>
            <Button className="btn-cover" variant="outline-light" href="https://emrg.embodied.net/collab/" mdxType="Button">
              More
              <span className="ps-2">
                <FontAwesomeIcon icon="external-link-alt" mdxType="FontAwesomeIcon" />
              </span>
            </Button>
          </AnimateInView>
        </Col>
      </Row>
    </Container>
  </BackgroundImage>
    </Section>
    <a id="about" />
    <Section variant="accent-5
" mdxType="Section">
  <BackgroundGradient mdxType="BackgroundGradient">
    <Container fluid mdxType="Container">
      <Row className="pt-5 justify-content-center" mdxType="Row">
        <Col xs={12} sm={10} lg={8} mdxType="Col">
          <AnimateInView variant={movRevealRight} mdxType="AnimateInView">
            <h2 className="cover">About</h2>
            <p>
              John Crawford is an intermedia artist, performance director and systems designer. Engaging directly with the emerging technologies of our time, his work creates rich immersive environments and compelling interactive experiences through embodied interaction.
            </p>
            <Image filename="jc-rehearsal-bw.jpg" alt="John Crawford" container="float-end w-25 mx-5 mb-3 d-none d-lg-block" outline texture mdxType="Image" />
            <p className="small">
              His artistic projects are performed in theatres, exhibited in galleries and presented as interactive installations, featuring creative exploration of environmentalism, climate justice and community engagement. His work investigates how the human desire to transform our environment is unleashing destructive forces that endanger the world and everything in it, including ourselves.                 
            </p>
            <p className="small">
              Projects currently in development include <span className="text-nowrap"><a href="http://crisis.artlab.eco">Cryosphere Crisis</a></span>, <span className="text-nowrap"><a href="http://trace.artlab.eco">Leave A Trace</a></span> and <span className="text-nowrap"><a href="http://yomo.artlab.eco">YO~MO</a></span>. He originated the "Active Space" concept for his intermedia software framework which he has continued to evolve and develop for almost thirty years, dedicated to connecting body-centered artistic practices with evolving methods for expanded media performance and multi-site interactivity.
            </p>
          </AnimateInView>
        </Col>
      </Row>
      <Row className="pb-5 justify-content-center" mdxType="Row">
        <Col xs={12} sm={10} lg={8} mdxType="Col">
              <AnimateInView variant={movAppear} threshold="0.1" mdxType="AnimateInView">
                <Expandable promptText="More bio" mdxType="Expandable">
                  <small>
                    <p>{`He is Professor of Intermedia Arts in the Dance Department at University of California, Irvine, where he directs the `}<span className="text-nowrap"><a href="http://emrg.embodied.net">{`Embodied Media Research Group`}</a></span>{`, featuring a range of projects, courses and collaboratories that integrate socially engaged artmaking with emergent media and connected design. At UCI he was Associate Dean in the Claire Trevor School of the Arts and has served in many other leadership roles. He is a strong advocate for the university's initiatives to expand transdisciplinary arts-based creativity, including the campus-wide Digital Arts Minor program which he directed for nine years.`}</p>
                    <p>{`He is the co-founder of Eco ArtLab and Eco DesignLab, two nonprofit organizations employing art and design to respond to the escalating crisis driven by the rapidly evolving human capacity to alter planetary ecosystems. `}<span className="text-nowrap"><a href="http://artlab.eco">{`Eco ArtLab`}</a></span>{` creates artistic performances and exhibitions that enhance ecological awareness, engaging with new forms of expression enabled by the emerging media technologies of our time. `}<span className="text-nowrap"><a href="http://designlab.eco">{`Eco DesignLab`}</a></span>{` is developing an online platform to support transdisciplinary artistic projects, drawing on insights gained from collaborative explorations in dance, music and theatre. These new initiatives are positioned as creative hubs to support synergistic groups of artists and designers concerned with environmental issues.`}</p>
                    <p>{`Active as an intermedia artist since 1992, Crawford has shown his work across North America and in Asia, Europe and South America, and frequently has been a visiting artist and researcher at universities and other venues in the United States, Europe, China, Japan and India. He is a regular participant in transdisciplinary research projects connecting performing arts and emergent media with computer science, engineering, and medicine.`}</p>
                    <p>{`Prior to working as a professor, he led a dual life, alternating between parallel vocations: theatre artist and software developer. As a theatre director and actor in the 1970s, he studied with Sanford Meisner at the Neighborhood Playhouse in New York, worked with theatre companies including Factory Theatre Lab and Theatre Passe Muraille, and directed an experimental performance group in Vancouver, Canada. As a software designer and research manager in the 1980s and 1990s, his credits included projects for Adobe, Microsoft and many other companies.`}</p>
                  </small>
                </Expandable>
              </AnimateInView>
        </Col>
      </Row>
    </Container>
  </BackgroundGradient>
    </Section>
    <a id="contact" />
    <Section variant="accent" mdxType="Section">
  <BackgroundGradient mdxType="BackgroundGradient">
    <Container fluid mdxType="Container">
      <Row className="pt-5 justify-content-center" mdxType="Row">
        <Col xs={12} sm={10} lg={8} mdxType="Col">
          <AnimateInView variant={movRevealRight} mdxType="AnimateInView">
            <h2 className="cover">Contact</h2>
            <div className="list-group pb-5">
              <div className="list-group-item">
                <div className="small text-uppercase fw-light">Email</div>
                <Obfuscate className="text-reset text-decoration-none" linkText="unavailable" email="john.crawford@uci.edu" mdxType="Obfuscate" />
              </div>
              <div className="list-group-item">
                <div className="small text-uppercase fw-light">LinkedIn</div>
                <Obfuscate className="text-reset  text-decoration-none" linkText="unavailable" href="https://www.linkedin.com/in/johncrawford" mdxType="Obfuscate" />
              </div>
              <div className="list-group-item">
                <div className="small text-uppercase fw-light">Delivery</div>
                University of California, Irvine<br />
                300 Mesa Arts Building<br />
                Irvine, California 92697-2775<br />
                USA
              </div>
            </div>
          </AnimateInView>
        </Col>
      </Row>
    </Container>
  </BackgroundGradient>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      