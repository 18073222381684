import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'

import BackgroundGradient from './background-gradient'
import scrollTo from '../utils/scroll-to'

const Banner = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 767 })
  const subtitleItems = ['Artist', 'Director', 'Designer', 'Professor']
  const handleClick = () => {
    scrollTo('#portfolio')
  }

  return (
    <BackgroundGradient height="40rem">
      <Container className="h-100" fluid>
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs={12} sm={10} lg={8}>
            <h1>John Crawford</h1>
            <p className="pb-3 lead text-pre-wrap">{
              subtitleItems.map((item, index) => (
                <span key={index}>
                  {index > 0 && (isSmallScreen ? <br /> :  '  |  ')}
                  {item}
                </span>
              ))
            }
            </p>
            <Button className="btn-cover" variant="outline-light" onClick={handleClick}>
              More
              <span className='ps-2'>
                <FontAwesomeIcon icon='arrow-down' />
              </span>
            </Button>
          </Col>
        </Row>
      </Container>
    </BackgroundGradient>
  )
} 

export default Banner
