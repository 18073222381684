import { library } from '@fortawesome/fontawesome-svg-core'

// solid
// prefix = 'fas' (default)
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

library.add(faAngleDown)
library.add(faAngleUp)
library.add(faArrowDown)
library.add(faArrowUp)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faCircle)
library.add(faExternalLinkAlt)
library.add(faSquare)

// regular
// prefix = 'far'
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons'
import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons'

library.add(farCircle)
library.add(farSquare)

// brands
// prefix = 'fab'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebook)
library.add(faFacebookSquare)
library.add(faInstagram)
library.add(faInstagramSquare)
library.add(faTwitter)
library.add(faTwitterSquare)
library.add(faYoutube)
library.add(faYoutubeSquare)
