import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyBackgroundImage from 'gatsby-background-image'

import detectMobile from '../utils/detect-mobile'

interface Props {
  children?: React.ReactNode
  filename: string
  height: string
}

const BackgroundImage = ({ children, filename, height }: Props) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          img: fluid(quality: 60, maxWidth: 1920) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const node = allImageSharp.nodes.find(n => n.img.originalName === filename)
  const bgImageStack = [
    `url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%224%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.46875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E')`,
    'linear-gradient(rgba(40,48,89,0.4), rgba(40,48,89,0.4))',
    node?.img,
  ]
  const bgHeight = height ?? 0
  return (
    <GatsbyBackgroundImage
      fluid={bgImageStack}
      style={{
        backgroundAttachment: detectMobile() ? 'scroll' : 'fixed',
        height: bgHeight
      }}
    >
      {children}
    </GatsbyBackgroundImage>
  )
}

export default BackgroundImage
