import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'

import './brand-icon.scss'

interface Props {
  brand: string
  url: string
  title: string
}

const BrandIcon = ({ brand, url, title }: Props) => {
  return (
    <a className="brand-icon" role="button" href={url} title={title}>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon className="brand-icon-bg" icon="square" color="black" />
        <FontAwesomeIcon icon={['fab', brand + '-square']} />
      </span>
    </a>
  )
}

export default BrandIcon
