import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

import detectMobile from '../utils/detect-mobile'

interface Props {
  children?: React.ReactNode
  variant: object
  threshold: number
}

const movDefault = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
}

const AnimateInView = ({ children, variant, threshold }: Props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: threshold,
  });
  const isMobile = detectMobile()
  return (
    <div ref={ref}>
      <motion.div
        initial={isMobile ? 'visible' : 'hidden'}
        animate={(isMobile || inView) ? 'visible' : 'hidden'}
        variants={variant}
      >
        {children}
      </motion.div>
    </div>
  )
}

AnimateInView.defaultProps = {
  variant: movDefault,
  threshold: 0.9,
}

export default AnimateInView