import React from 'react'

import './section.scss'

interface Props {
  children: React.ReactNode
  variant: string
}

const Section = ( { children, variant }: Props ) => {
  return (
    <section className={`full-width ${variant}`} >
      {children}
    </section>
  )
}

Section.defaultProps = {
  variant: ''
}

export default Section

