import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { motion } from 'framer-motion'
import Obfuscate from 'react-obfuscate'
import { useInView } from 'react-intersection-observer'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'

import AnimateInView from '../components/animate-in-view'
import BackgroundGradient from '../components/background-gradient'
import BackgroundImage from '../components/background-image'
import Banner from '../components/banner'
import BrandIcon from '../components/brand-icon'
import Expandable from '../components/expandable'
import Footer from '../components/footer'
import Header from '../components/header'
import Image from '../components/image'
import Meta from '../components/meta'
import Section from '../components/section'

import '../scss/main.scss'

const movAppear = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
}

const GlobalComponents = {
  AnimateInView,
  BackgroundGradient,
  BackgroundImage,
  Banner,
  BrandIcon,
  Button,
  Card,
  Col,
  Container,
  Expandable,
  FontAwesomeIcon,
  Image,
  Obfuscate,
  Row,
  Section,
}

interface Props {
  children: React.ReactNode
  location: Location
  pageContext: unknown
}

const MdxDefault = ({ children, location, pageContext }: Props) => {
  const { title, author, description } = pageContext.frontmatter
  const { ref, inView } = useInView({ threshold: 0.1 })
  return (
    <>
      <Meta title={title} author={author} description={description} />
      <header>
        <a id="top" />
        <Header visible={!inView} location={location} />
      </header>
      {location.pathname === '/' &&
        <motion.section id="banner" ref={ref} initial="hidden" animate="visible" variants={movAppear}>
          <Banner />
        </motion.section>
      }
      <div id="wrapper">
        <MDXProvider components={GlobalComponents}>
          {children}
        </MDXProvider>
        <footer>
          <Footer author={author} />
        </footer>
      </div>
    </>
  )
}

export default MdxDefault
